
import { Component, Vue } from "vue-property-decorator";
import ForgotPasswordForm from "./components/ForgotPasswordForm.vue";

@Component({
  name: "ForgotPassword",
  components: {
    ForgotPasswordForm,
  },
})
export default class ForgotPassword extends Vue {}
