
import { Component, Vue } from 'vue-property-decorator';
import TextField from '@/ui-components/TextField/TextField.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { rules } from '@/validation-rules/index';
import { sendPasswordResetEmailAction } from '@/actions/auth/actions';
import { publicRouteNames } from '@/route-names/public';
import { errors } from '@/statics/errors';
import Succeeded from './Succeeded.vue';

@Component({
  name: 'ForgotPasswordForm',
  components: {
    IButton,
    TextField,
    Succeeded,
  },
})
export default class ForgotPasswordForm extends Vue {
  public valid = false;
  public loading = false;
  public emailNotFoundError = false;
  public succeeded = false;
  public email = '';
  public loginRoute = publicRouteNames.LOGIN;
  // TODO change validation to be more simple
  public get emailRules() {
    return [
      rules.required(this.$i18n.t('field_required') as string),
      rules.emailFormat(this.$i18n.t('invalid_email') as string),
      () => !this.emailNotFoundError || this.$i18n.t('email_not_found_error'),
    ];
  }

  public handleSuccess() {
    this.loading = false;
    this.succeeded = true;
  }

  public handleFailure(error: any) {
    this.loading = false;
    const { code } = error;
    const { EMAIL_NOT_FOUND_ERROR } = errors;
    const form = this.$refs.form as any;

    if (code === EMAIL_NOT_FOUND_ERROR) {
      this.emailNotFoundError = true;
      form.validate();
      return;
    }
  }

  public async sendResetPasswordLink() {
    try {
      this.loading = true;
      await sendPasswordResetEmailAction(this.email);
      this.handleSuccess();
    } catch (error) {
      this.handleFailure(error);
    }
  }
}
