
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Succeeded",
})
export default class Succeeded extends Vue {
  // TODO: Set home page target
  public homeRoute = 'https://shasha.io';
}
